import React, { useRef } from 'react'
import { Helmet } from "react-helmet"
import KapsalonBanner from '../components/landingpage/kapsalon/kapsalonbanner/banner'
import LandingPageCtas from '../components/landingpage/shared/ctas/landingpagecta'
import KapsalonCta from '../components/landingpage/kapsalon/kapsaloncta/KapsalonCta'
import KapsalonWieZijnWij from '../components/landingpage/kapsalon/kapsalonwiezijnwij/KapsalonWieZijnWij'
import KapsalonSellingPoint from '../components/landingpage/kapsalon/kapsalonsellingpoint/KapsalonSellingPoint'
import KapsalonContact from '../components/landingpage/kapsalon/kapsaloncontact/KapsalonContact'
import Contact from '../components/contact/contact'

export default function Kapsalon({location, data}){

    const contactRef = React.createRef();

    const scrollToContact = () => contactRef.current.scrollIntoView();

    return(
        <>
        <Helmet htmlAttributes={{
        lang: 'nl',
        }}>
            <meta charSet="utf-8" />
            <title>Toolmakers maakt de ideale website voor jouw Kapsalon</title>
            <meta name="description" content="Toolmakers: De ideale website voor kappers." />
        </Helmet>
        <div>
            <KapsalonBanner image="macbook.png" />
            <LandingPageCtas/>
            <KapsalonWieZijnWij />
            <KapsalonSellingPoint scrollToContact={scrollToContact} />
            <Contact reference={contactRef} theme="kapsalon" />
        </div>
        </>
    )
}