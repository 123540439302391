import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'

import Customers from '../../../../images/customers.svg'
import Puzzle from '../../../../images/puzzle.svg'
import Money from '../../../../images/money.svg'

import './landingpagecta.scss'

const LandingPageCtas = () => {

    return (
        <div className="c-landing-page-cta__container container">
            <div className="c-landing-page-cta">
                <Customers style={{height:"60px", width:"60px"}} />
                <h1 className="c-landing-page-cta__title">
                    Bereik meer klanten
                </h1>
                <p className="c-landing-page-cta__sub-title">
                    Met een website vinden nieuwe klanten sneller de baan en krijgen bestaande klanten een kans om
                    op de hoogte te blijven van uw kapsalon.
                </p>
            </div>
            <div className="c-landing-page-cta">
                <Money style={{height:"60px", width:"60px"}} />
                <h1 className="c-landing-page-cta__title">
                    Slechts €14,99 per maand
                </h1>
                <p className="c-landing-page-cta__sub-title">
                    Geen start kost. Annuleer wanneer u wil. 
                </p>
            </div>
            <div className="c-landing-page-cta">
                <Puzzle style={{height:"60px", width:"60px"}} />
                <h1 className="c-landing-page-cta__title">
                    Integreer met uw bestaand reserveringssysteem
                </h1>
                <p className="c-landing-page-cta__sub-title">
                   Wij integreren uw bestaand reservatie systeem of voegen een nieuw online reservingssysteem toe vanaf €34.99/maand 
                </p>
           </div>
        </div>
    )
}

export default LandingPageCtas