import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './banner.scss';

import ArrowLanding from '../../../../images/arrow_landing.svg'

const KapsalonBanner = ({image}) => {
    return <div className="c-kap-header__container">
        <div className="c-kap-header yellow">
            <div className="container">
                <a href="/">
                    <h2 className="c-kap-header__brand">
                        Toolmakers
                    </h2>
                </a>
                    <h1 className="c-kap-header__title">
                        Krijg meer klanten & bekendheid
                    </h1>
                    <h3 className="c-kap-header__sub-title">
                        Ga binnen de week online zonder grote kost
                    </h3>
                <a className="c-kap-header__image" href={"/kapsalon-kelly"}>
                    <div className="c-kap-header__image-arrow">
                        <div className="c-kap-header__image-arrow-text">Klik hier om <br /> een voorbeeld <br />te bekijken</div>
                        <ArrowLanding width="50px" className="c-kap-header__image-arrow-img" style={{color:"white"}}/>
                    </div>
                    <StaticImage src={`../../../../images/macbook.png`} placeholder="none" style={{ height:"100%", width:"100%",zIndex:0, margin:"auto"}}/>
                </a>
            </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#FE4A49" fill-opacity="1" d="M0,192L60,192C120,192,240,192,360,170.7C480,149,600,107,720,106.7C840,107,960,149,1080,154.7C1200,160,1320,128,1380,112L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
        </svg>
    </div>
}

export default KapsalonBanner;