import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import './KapsalonWieZijnWij.scss'

const KapsalonWieZijnWij = () => {

   return (
       <div className="container c-kapsalon-wie-zijn-wij">
           <div className="c-kapsalon-wie-zijn-wij__caption">
               "Hallo, Ik ben Diederik uit Heusden. Ik sta u graag bij om uw 
               kapsalon online te krijgen om meer klanten te bereiken. Als u geïnteresseerd bent laat dan uw gegevens achter en dan contacteer ik u graag om u verder te helpen!"
           </div>
           <div className="c-kapsalon-wie-zijn-wij__diederik">
               <StaticImage src={`../../../../images/diederik_color.jpg`} height={80} width={80} quality={100}className="c-kapsalon-wie-zijn-wij__diederik-img" />
               <div className="c-kapsalon-wie-zijn-wij__diederik-name">Diederik Mathijs</div>
               <div className="c-kapsalon-wie-zijn-wij__diederik-title">Oprichter</div>
           </div>
       </div>
   ) 
}

export default KapsalonWieZijnWij;