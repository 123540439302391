import React from 'react'
import './KapsalonSellingPoint.scss'

import CheckWhite from '../../../../images/check_white.svg'

const ListItem = ({ text, color = "white" }) => {
    return (
        <li className="c-kapsalon-selling-point__list-item-price-list-item">
            <CheckWhite height={20} width={20} style={{ color: color }} className="c-kapsalon-selling-point__list-item-price-icon" /> {text}
        </li>
    )
}

const KapsalonSellingPoint = ({ scrollToContact }) => {

    return (
        <div className="c-kapsalon-selling-point__wrapper">
            <div className="container c-kapsalon-selling-point">
                <div className="c-kapsalon-selling-point__list">
                    <div className="c-kapsalon-selling-point__list-item primary">
                        <div>
                            <div className="c-kapsalon-selling-point__list-item-header">
                                Website
                            </div>
                            <div className="c-kapsalon-selling-point__list-item-sub-header">
                                voor kappers
                            </div>
                            <div className="c-kapsalon-selling-point__list-item-price">
                                €19.99 <span className="c-kapsalon-selling-point__list-item-price-small">per maand</span>
                            </div>
                            <ul className="c-kapsalon-selling-point__list-item-price-list">
                                <ListItem text={".be domeinnaam"} />
                                <ListItem text={"Website"} />
                                <ListItem text={"1 E-mail address (uitbreiding mogelijk)"} />
                                <ListItem text={"Hoog gerangschikt in Google"} />
                                <ListItem text={"Beveiligd met HTTPS"} />
                            </ul>
                        </div>
                        <div className="c-kapsalon-selling-point__btn" onClick={scrollToContact}>
                            contacteer ons 
                        </div>
                    </div>
                    <div className="c-kapsalon-selling-point__list-item secondary">
                        <div>
                            <div className="c-kapsalon-selling-point__list-item-header">
                                Online boeking systeem
                            </div>
                            <div className="c-kapsalon-selling-point__list-item-sub-header red">
                                voor kappers
                            </div>
                            <div className="c-kapsalon-selling-point__list-item-price">
                                €34.99 <span className="c-kapsalon-selling-point__list-item-price-small">per maand</span>
                            </div>
                            <ul className="c-kapsalon-selling-point__list-item-price-list">
                                <ListItem text={"Oneindig veel producten"} color={"#444"} />
                                <ListItem text={"Integreert met Google calendar, Microsoft, iCal"} color={"#444"} />
                                <ListItem text={"Beheer uw agenda in een centraal systeem"} color={"#444"} />
                                <ListItem text={"Meerdere werknemers"} color={"#444"} />
                                <ListItem text={"Integratie met onze website"} color={"#444"} />
                            </ul>
                        </div>
                        <div className="c-kapsalon-selling-point__btn secondary" onClick={scrollToContact}>
                            Contacteer ons 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KapsalonSellingPoint